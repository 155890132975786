<template>
  <div class="px-0 container-fluid h-100">
    <b-row
      v-if="isFetchingFilters"
      class="h-100 align-items-center"
    >
      <b-col class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem;"
        />
      </b-col>
    </b-row>
    <div v-else>
      <statistics-filters
        :extra-filters="getExtraFilters(filterOptions, analyzerDetails, connectorType)"
        :base-filters="getBaseFilters()"
        show-time-range
        button-text="Search"
        :configuration="filterConfig"
        @fetchData="search"
      >
        <template #customSearch>
          <CustomSearch />
        </template>
        <template #categories>
          <categories />
        </template>
      </statistics-filters>
      <b-overlay :show="isFetching" rounded="sm">
        <b-card
          class="r-75"
          title="Calls"
        >
          <b-row>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="pagination.count"
                :per-page="pagination.perPage"
                size="sm"
                class="mb-3 mt-1"
                aria-controls="logs-table"
              />
            </b-col>
            <b-col cols="auto">
              <b-button
                v-if="isSuperUser"
                :disabled="isExporting"
                class="mr-2"
                variant="primary"
                @click="openExportModal"
              >
                <span v-if="isExporting">
                  <b-spinner small />
                </span>
                <font-awesome-icon v-else icon="download" />
                Export to Excel
              </b-button>
              <b-modal
                id="export-modal"
                scrollable
                title="Export calls"
                @hide="$emit('hide', null)"
                @ok="exportToExcel"
              >
                Fields to include in export
                <b-form-checkbox-group
                  v-model="exportFields"
                  :options="exportFieldsOptions"
                  stacked
                />
              </b-modal>
              <b-btn-group>
                <b-button
                  variant="primary"
                  :disabled="selected.length === 0"
                  @click="rehandleCallsProxy()"
                >
                  Re-handle <span v-if="selected.length">{{ selected.length }}</span>
                  selected {{ selected.length === 1 ? 'call' : 'calls' }}
                </b-button>  <b-button
                  variant="primary"
                  @click="rehandleAllCallsProxy()"
                >
                  Re-handle all calls
                </b-button>
              </b-btn-group>
            </b-col>
          </b-row>
          <table-data
            id="logs-table"
            ref="logs-table"
            :items="items"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :fields="callFields"
            class="mb-0"
            show-empty
            hover
            @row-clicked="openLogDetails"
          >
            <template #head(selected)="data">
              <b-form-checkbox
                v-model="selectAll"
                @change="toggleAll()"
              >
                {{ data.label }}
              </b-form-checkbox>
            </template>
            <template #cell(selected)="row">
              <b-form-checkbox
                v-model="row.item.selected"
                :disabled="rehandlingDisabled(row.item)"
                @change="v=>toggleSelectCall(row.item.id, v)"
              />
            </template>
            <template #cell(firstMessage)="row">
              {{ getFirstUserMessage(analyzerDetails, row.item) }}
            </template>
            <template #cell(callEnded)="row">
              {{ new Date(row.item.callEnded).toLocaleDateString() }}<br>
              {{ new Date(row.item.callEnded).toLocaleTimeString() }}
            </template>
            <template #cell(tag)="row">
              <b-badge v-if="row.item.mappedTags.main" class="mr-1 tag-badge" variant="primary">
                {{ row.item.mappedTags.main }}
              </b-badge>
              <b-badge v-if="row.item.mappedTags.sub" class="mr-1 tag-badge">
                {{ row.item.mappedTags.sub }}
              </b-badge>
            </template>
            <template #cell(topPrediction)="row">
              {{ formatPrediction(row.item.topPrediction) }}
            </template>
            <template #cell(fullTranscript)="row">
              <span :class="row.item.fullTranscript ? 'text-success' : ''">
                <font-awesome-icon :icon="row.item.fullTranscript ? 'check-circle' : 'times-circle'" />
              </span>
            </template>
            <template #cell(actions)="row">
              <span>
                <b-button
                  v-b-tooltip.noninteractie.viewport.left.hover="'View log in external system'"
                  class="mr-1"
                  size="sm"
                  variant="info"
                  @click="openExternalLink(row.item)"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-button>
                <b-button
                  v-b-tooltip.noninteractie.viewport.left.hover="'View log in new tab'"
                  size="sm"
                  variant="primary"
                  @click="openLogDetailsNewPage(row.item)"
                >
                  <font-awesome-icon icon="external-link-alt" />
                </b-button>
              </span>
            </template>
          </table-data>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapMutations, mapGetters,
} from 'vuex';
import TableData from 'supwiz/components/TableData.vue';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
import StatisticsFilters from 'supwiz/components/statisticsFilters/StatisticsFilters.vue';
import { CallStatusTypes, sentimentOptions, featureSupport } from '@/js/constants';
import { objToCamel, strToSnake } from 'supwiz/util/data';
import { getFirstUserMessage, getExtraFilters, getBaseFilters } from '@/js/utils';
import Categories from '@/components/Analyzer/Categories.vue';
import CustomSearch from '@/components/CustomSearch.vue';

export default {
  name: 'AnalyzerCalls',
  components: {
    TableData,
    CustomSearch,
    StatisticsFilters,
    Categories,
  },
  data() {
    return {
      isFetchingFilters: false,
      filterOptions: null,
      sentimentOptions,
      minKeyWidth: 170,
      selected: [],
      selectAll: false,
      filterConfig: null,
      items: [],
      sortBy: 'callEnded',
      sortDesc: false,
      exportFields: ['id', 'call_ended', 'agent_id'],
    };
  },
  computed: {
    ...mapState('call', ['pagination', 'isFetching']),
    ...mapState('analyzer', { analyzers: 'items' }),
    ...mapState('analyzer', { analyzerDetails: 'details' }),
    ...mapGetters('statisticsFiltersStore', ['filters', 'extraSelectedFilters']),
    ...mapGetters('call', ['getSavedFilters', 'isExporting']),
    ...mapGetters('connector', ['typeFromId']),
    ...mapGetters('auth', ['isSuperUser']),
    baseFilters() {
      return [
        {
          key: 'search', label: 'Content', type: 'text', tooltip: 'Enter expression you want to search for', custom: true,
        },
      ];
    },
    exportFieldsOptions() {
      return [
        { text: 'Call ID', value: 'id' },
        { text: 'Call ended', value: 'call_ended' },
        { text: 'Agent id', value: 'agent_id' },
        { text: 'Duration', value: 'duration' },
        { text: 'Tag', value: 'tag' },
        { text: 'Subtag', value: 'subtag' },
        { text: 'Status', value: 'status' },
        { text: 'Full transcript', value: 'transcript_full' },
        { text: 'Raw transcript', value: 'transcript' },
        { text: 'Sentiment', value: 'sentiment_call' },
        { text: 'Conversation Score', value: 'conversation_score' },
      ];
    },
    analyticsFilters() {
      return ['agentId', 'tag', 'status', 'sentiments', 'transcribeDuration',
        'excludeAll', 'includeAll', 'excludeCategory', 'includeCategory'];
    },
    connectorType() {
      return this.typeFromId(this.analyzerDetails.dataSource);
    },
    supportsPushTags() {
      return featureSupport.pushTags.map((e) => e.value).includes(this.connectorType);
    },
    fields() {
      return [
        { key: 'selected', label: '', tdClass: 'actions-col' },
        { key: 'firstMessage', label: 'First user message' },
        {
          key: 'callEnded', label: 'Call ended', tdClass: 'call-ended-col', sortable: true,
        },
        { key: 'agentId', label: 'Agent id', tdClass: 'agent-id-col' },
        this.analyzerDetails.classification ? { key: 'tag', label: 'Tag', tdClass: 'tag-col' } : {},
        {
          key: 'status', label: 'Status', formatter: (s) => CallStatusTypes.find((v) => v.value === s).text, sortable: true,
        },
        { key: 'fullTranscript', label: 'Full transcript', tdClass: 'text-center' },
        { key: 'actions', label: '', tdClass: 'links-col' },
      ];
    },
    currentPage: {
      get() {
        return this.pagination.page;
      },
      set(val) {
        this.updatePagination({ page: val });
      },
    },
    callFields() {
      const copy = this.fields;
      if (this.analyzerDetails.sentimentAnalysis) {
        copy.splice(6, 0, { key: 'sentimentCall', label: 'Sentiment' });
      }
      if (this.analyzerDetails.classification) {
        copy.splice(6, 0, { key: 'topPrediction', label: 'Top prediction' });
      }
      return copy;
    },
    allCurrentCallsSelected() {
      const items = this.$refs['logs-table'].$refs.table.localItems;
      return items.every((e) => this.selected.includes(e.id));
    },
    getSorting() {
      return { sortBy: this.sortBy, sortDesc: this.sortDesc };
    },
  },
  watch: {
    async getSorting() {
      this.items = await this.fetchCallsProxy();
    },
    async currentPage(n, o) {
      if (n !== o) {
        this.items = await this.fetchCallsProxy();
      }
    },
    async analyzerDetails(newVal) {
      if (newVal) {
        this.items = await this.fetchCallsProxy();
      }
    },
  },
  beforeDestroy() {
    this.setSavedFilters({
      filters: cloneDeep(this.filters),
      extraSelectedFilters: this.extraSelectedFilters.map((e) => e.key),
    });
  },
  created() {
    if (this.analyzerDetails) {
      this.fetchFilters().then(async () => {
        // set saved filters once fetching is done
        this.filterConfig = this.getSavedFilters;
        if (this.filterConfig) {
          this.setFilters(this.filterConfig.filters);
        } else {
          this.setFilters(this.defaultFilterValues());
        }
        this.items = await this.fetchCallsProxy();
      });
    }
  },
  methods: {
    ...mapActions('call', {
      fetchCalls: 'fetchCalls',
      rehandleCalls: 'rehandleCalls',
      exportCalls: 'exportCalls',
      rehandleAllCalls: 'rehandleAllCalls',
    }),
    ...mapActions('externalSystem', ['fetchGroups', 'fetchFilterValues']),
    ...mapMutations('call', ['updatePagination', 'updateCallFilter', 'setSavedFilters']),
    ...mapMutations('statisticsFiltersStore', ['setFilters']),
    ...mapActions('category', { fetchCategories: 'fetchItems' }),
    getFirstUserMessage,
    getBaseFilters,
    getExtraFilters,
    openExportModal() {
      this.$nextTick(() => {
        this.$bvModal.show('export-modal');
      });
    },
    getMonday() {
      const d = new Date();
      const day = d.getDay();
      const diff = d.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d.setDate(diff));
    },
    defaultFilterValues() {
      return {
        startDate: this.getMonday(),
        endDate: new Date(),
        startTime: 0,
        endTime: 24,
        search: {
          advanced: false,
          operator: 'OR',
          stemsAndSynonyms: true,
          speakerFilter: 'both',
          negate: false,
          expressions: [],
        },
        agentId: [],
        status: [],
        tag: [],
        transcribeDuration: [],
        sentiments: [],
        includeCategory: [],
        includeAll: true,
        excludeCategory: [],
        excludeAll: true,
      };
    },
    getBestPrediction(prediction) {
      let pMax = 0;
      let cMax = null;
      for (const [c, p] of Object.entries(prediction)) {
        if (p > pMax) {
          cMax = c;
          pMax = p;
        }
      }
      cMax = this.analyzerDetails?.tagMapping[cMax]?.label || cMax;
      return [cMax, pMax];
    },
    getTime(date, time) {
      date.setHours(time);
      const minutes = (time * 10) % 10 !== 0 ? 30 : 0;
      date.setMinutes(minutes);
      date.setSeconds(0);
      if (time === 24) {
        date.setMilliseconds(-1);
      }
      return date;
    },
    paramsGenerator() {
      if (!this.analyzerDetails) {
        return [];
      }
      const params = {
        sort_by: `${this.sortDesc ? '-' : ''}${strToSnake(this.sortBy)}`,
      };
      const startTime = this.filters.startTime;
      const endTime = this.filters.endTime;
      const analyticsFilter = {
        analyzer_id: [this.analyzerDetails.id],
        exclude_empty_visitor_transcript: false,
      };
      for (const [filter, value] of Object.entries(this.filters)) {
        if (value && !['startTime', 'endTime'].includes(filter)) {
          if (filter === 'startDate') {
            const start = this.getTime(value, startTime);
            params.start_time = start;
          } else if (filter === 'endDate') {
            const end = this.getTime(value, endTime);
            params.end_time = end;
          } else if (this.analyticsFilters.includes(filter)) {
            analyticsFilter[strToSnake(filter)] = value;
          } else if (filter === 'search') {
            const searchExp = this.getSearchExpValue(value);
            if (searchExp) {
              analyticsFilter.search = {
                language: this.analyzerDetails.language,
                stems_and_synonyms: value.stemsAndSynonyms,
                search_expression: searchExp,
              };
            }
          } else if (value || value.length) {
            params[strToSnake(filter)] = value;
          }
        }
      }
      params.analytics_filter = analyticsFilter;
      return params;
    },
    getSearchExpValue(value) {
      if (!value.expressions?.length && !value.values?.length) {
        return null;
      }
      let exp;
      if (value.advanced === false) {
        return { operator: 'WORD', speaker_filter: 'both', expressions: value.expressions[0] };
      }
      if (value.operator === 'WORD') {
        // Top level operator uses expressions only
        const expressions = value.values || value.expressions;
        exp = {
          operator: value.operator,
          speaker_filter: value.speakerFilter,
          expressions: expressions[0],
        };
      } else {
        const valuesAsExpressions = value.values?.map((x) => ({ operator: 'WORD', speaker_filter: null, expressions: x })) || [];
        exp = {
          operator: value.operator,
          speaker_filter: value.speakerFilter,
          expressions: value.expressions.map(this.getSearchExpValue)
            .filter(Boolean).concat(valuesAsExpressions),
        };
      }
      if (value.negate) {
        exp = { operator: 'NOT', speaker_filter: null, expressions: [exp] };
      }
      return exp;
    },
    async exportToExcel() {
      const params = this.paramsGenerator();
      const results = await this.exportCalls({ fields: this.exportFields, params });
      const rows = [[]];
      results.forEach((row) => {
        rows.push(row);
      });
      const DownloadHeader = 'data:text/csv;charset=utf-8,';
      const csv = `${DownloadHeader}\uFEFF${rows.map((x) => x.join(',')).join('\r\n')}`;
      const encodedUri = encodeURI(csv);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${'calls'}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    async fetchCallsProxy() {
      const params = this.paramsGenerator();
      const queryParams = {
        page: this.pagination.page,
      };
      const calls = await this.fetchCalls({ params, queryParams });
      if (calls.every((e) => this.selected.includes(e.id))) {
        this.selectAll = true;
        calls.map((element) => {
          const copy = element;
          copy.selected = true;
          return copy;
        });
      } else {
        this.selectAll = false;
        calls.map((element) => {
          const copy = element;
          if (this.selected.includes(element.id)) {
            copy.selected = true;
          } else {
            copy.selected = false;
          }
          return copy;
        });
      }
      return Object.values(calls.map(
        (o) => Object.assign(
          objToCamel(o),
          {
            fullTranscript: this.fullTranscript(o),
            topPrediction: this.getBestPrediction(o.prediction),
            mappedTags: this.getTags(o),
          },
        ),
      ));
    },
    async fetchFilters() {
      try {
        this.isFetchingFilters = true;
        const resp = await this.fetchFilterValues();
        this.filterOptions = resp.data;
        this.filterOptions.groups = await this.fetchGroups();
        await this.fetchCategories();
      } catch (error) {
        const filterValues = `agents${this.analyzerDetails.classification ? ' and tags' : ''}`;
        this.$store.dispatch('sidebar/showWarning', {
          title: `Failed to fetch filter values (${filterValues})`,
          text: error.message,
        });
      } finally {
        this.isFetchingFilters = false;
      }
    },
    async search() {
      this.updatePagination({ page: 1 });
      this.items = await this.fetchCallsProxy();
    },
    openLogDetails(item) {
      this.$router.push({ name: 'call-single', params: { callId: item.id } });
    },
    openLogDetailsNewPage(item) {
      const newPage = this.$router.resolve({ name: 'call-single', params: { callId: item.id } });
      window.open(newPage.href, '_blank');
    },
    openExternalLink(item) {
      window.open(item.externalUrl, '_blank');
    },
    formatPrediction(value) {
      if (value[0] === null) {
        return '';
      }
      return `${value[0]} (${value[1].toFixed(2)})`;
    },
    getTags(item) {
      return {
        main: item.tag,
        sub: item.subtag,
      };
    },
    fullTranscript(call) {
      if (!call.transcribe_duration || !call.duration) { return false; }
      if (call.transcribe_duration === -1) { return true; }
      return call.transcribe_duration >= call.duration;
    },
    rehandlingDisabled(item) {
      return item.status !== 'done'
      && item.status !== 'failed';
    },
    toggleSelectCall(id, value) {
      if (!value) {
        this.unselectCall(id);
      } else {
        this.selectCall(id);
      }
    },
    selectCall(id) {
      this.selected.push(id);
      if (this.allCurrentCallsSelected) {
        this.selectAll = true;
      }
    },
    unselectCall(id) {
      this.selected.splice(this.selected.indexOf(id), 1);
      this.selectAll = false;
    },
    async toggleAll() {
      const items = this.$refs['logs-table'].$refs.table.localItems;
      if (this.allCurrentCallsSelected) {
        items.forEach((element) => {
          Vue.set(element, 'selected', false);
          if (this.selected.includes(element.id)) {
            this.unselectCall(element.id);
          }
        });
      } else {
        items.forEach((element) => {
          Vue.set(element, 'selected', true);
          if (!this.selected.includes(element.id)) {
            this.selectCall(element.id);
          }
        });
      }
    },
    async rehandleCallsProxy() {
      await this.rehandleCalls(this.selected);
      this.selected = [];
      this.items = await this.fetchCallsProxy();
    },
    async rehandleAllCallsProxy() {
      const params = this.paramsGenerator();
      await this.rehandleAllCalls(params);
      this.selected = [];
      this.items = await this.fetchCallsProxy();
    },
  },
};
</script>
<style scoped>
.tag-badge{
  font-size: 90%;
}
::v-deep .actions-col{
  width: 60px;
}
::v-deep .links-col{
  width: 100px;
}
::v-deep .call-ended-col{
  width: 100px;
}
::v-deep .agent-id-col{
  min-width: 120px;
}
::v-deep .tag-col{
  min-width: 100px;
}
</style>
